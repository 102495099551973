import classnames from 'classnames';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import BpkIconMap from '@skyscanner/backpack-web/bpk-component-icon/sm/map';
import BpkIconNewWindow from '@skyscanner/backpack-web/bpk-component-icon/sm/new-window';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import type { HotelMapPreviewProps } from 'common-types/types/hotels-components/HotelMapPreview';

import STYLES from './HotelMapPreview.module.scss';

const HotelMapPreview = ({
  buttonLabel,
  className = undefined,
  dayViewUrl,
  googleStaticMapsUrl,
  title,
  useUpdatedStyle = false,
}: HotelMapPreviewProps) => {
  const originalButton = (
    <BpkButton secondary data-test-id="show-map">
      {buttonLabel}
    </BpkButton>
  );

  const updatedButton = (
    <BpkButton
      primaryOnLight
      data-test-id="show-map"
      className={STYLES.updatedButton}
    >
      <BpkIconMap className={STYLES.buttonMapIcon} />
      {buttonLabel}
      <BpkIconNewWindow className={STYLES.buttonNewWindowIcon} />
    </BpkButton>
  );

  const mapContainerStyles: string[] = [STYLES.mapContainer];
  if (useUpdatedStyle) {
    mapContainerStyles.push(STYLES.updatedStyle);
  }

  return (
    <div
      className={classnames(STYLES.HotelMapPreview, className)}
      data-testid="hotels-map-preview-section"
    >
      {title && (
        <div className={STYLES['HotelMapPreview__section-header']}>
          <BpkSectionHeader title={title} />
        </div>
      )}
      <a
        className={mapContainerStyles.join(' ')}
        data-testid="hotels-map-link"
        tabIndex={0}
        href={dayViewUrl}
        rel="nofollow"
      >
        <img
          className={STYLES.image}
          src={googleStaticMapsUrl}
          alt="hotel-map-preview-background"
        />
        <div className={STYLES.clickTarget}>
          {useUpdatedStyle ? updatedButton : originalButton}
        </div>
      </a>
    </div>
  );
};

export default HotelMapPreview;
